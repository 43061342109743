import React, { createContext, useContext, useRef } from "react";
import { driver } from "driver.js"; // ✅ FIXED IMPORT
import "driver.js/dist/driver.css";

const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const driverRef = useRef(
    driver({
      animate: true,
      overlayOpacity: 0.75,
      allowClose: true,
      overlayClickBehavior: "nextStep",
      showButtons: ["next", "previous", "close"],
      nextBtnText: "Next ▶",
      prevBtnText: "Back ◀",
      doneBtnText: "Finish ✅",
      closeBtnText: "Exit Tour ⏹",
    })
  );

  const startTour = () => {
    driverRef.current.setSteps([
      {
        element: "#logo",
        popover: {
          title: "ABM Respiratory Care",
          description: "Click here to return to the homepage.",
          side: "bottom",
          showButtons: ["next", "close"],
        },
      },
      {
        element: "#date-picker",
        popover: {
          title: "Date Range Selector",
          description: "Use this to filter therapy data by date range.",
          side: "bottom",
          showButtons: ["next", "previous", "close"],
        },
      },
      {
        element: "#therapy-list",
        popover: {
          title: "Therapy List",
          description: "Shows a list of all therapies available.",
          side: "right",
          showButtons: ["next", "previous", "close"],
        },
      },
      {
        element: "#graph",
        popover: {
          title: "Settings Vs Pressures and Volumes",
          description: "Provides a visual representation of therapy settings and delivered pressures/volumes. Zoom in for better visibility.",
          side: "right",
          showButtons: ["next", "previous", "close"],
        },
      },
      {
        element: "#add-graph",
        popover: {
          title: "Add Graph to Report",
          description: "Click on 'Add Graph' to move the current view to the report.",
          side: "left",
          showButtons: ["next", "previous", "close"],
        },
      },
      {
        element: "#generate-report-btn",
        popover: {
          title: "Generate Report",
          description: "Hover here to generate and download a therapy report. Preview the selected graphs before downloading.",
          side: "left",
          showButtons: ["done", "previous", "close", "next"],
        },
      },
    ]);

    driverRef.current.drive();
  };

  return (
    <TourContext.Provider value={{ startTour }}>
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
