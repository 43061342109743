/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./TopBar.css";

const TopBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  
  // Toggle mobile menu
  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  
  // Toggle submenu
  const toggleSubmenu = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };
  
  return (
    <div className="top-bar">
      <div className="top-head">
        {/* Top Bar Links */}
        <div className="head-items">
          <a href="https://abmrc.com//careers/" className="top-link">Careers </a>
          <a href="https://abmrc.com/about/#locations" className="top-link">Locations </a>
          <a href="https://abmrc.com/schedule-a-demo/" className="top-link">Schedule a Demo</a>
        </div>
        
        {/* Social Icons */}
        <div className="header-social">
          <a href="https://www.linkedin.com/company/abmrc/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://www.facebook.com/ABM-Respiratory-Care-102320835281322" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com/abmcare?lang=en" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
      
      {/* Main Navigation */}
      <nav className="main-nav">
        <div className="logo">
          <a href="https://abmrc.com">
            <img src="https://abmrc.com/wp-content/uploads/2021/02/ABM-Logo-08.png" alt="ABM Logo" />
          </a>
        </div>
        
        {/* Mobile Menu Toggle Button */}
        <button 
          className="mobile-menu-toggle" 
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
        >
          <i className={`fas ${mobileMenuOpen ? "fa-times" : "fa-bars"}`}></i>
        </button>
        
        {/* Desktop Menu (hidden on mobile) */}
        <div className="desktop-nav">
          <ul className="nav-menu">
            <p><a href="https://abmrc.com/about/">About</a></p>
            
            <p className="dropdown">
              <a href="https://abmrc.com/products/">Products</a>
              <ul className="dropdown-menu">
                <li><a href="https://abmrc.com/home-care/">Home Care Solutions</a></li>
                <li><a href="https://abmrc.com/hospital-care/">Hospital Care Solutions</a></li>
              </ul>
            </p>
            
            <p className="dropdown">
              <a href="#">Resources</a>
              <ul className="dropdown-menu">
                <li><a href="https://abmrc.com/medical-education/">Medical Education</a></li>
                <li><a href="https://abmrc.com/press-releases/">Press Releases</a></li>
                <li><a href="https://abmrc.com/government-programs/">Government Programs</a></li>
                <li><a href="https://abmrc.com/news/">News</a></li>
                <li><a href="https://eifu.abmrc.com/">Manuals</a></li>
              </ul>
            </p>
            
            <p><a href="https://abmrc.com/contact/">Contact us</a></p>
          </ul>
        </div>
      </nav>
      
      {/* Mobile Menu (expanded below main nav) */}
      {mobileMenuOpen && (
        <div className="mobile-nav">
          <div className="mobile-menu-items">
            <div className="mobile-menu-item">
              <a href="https://abmrc.com/about/">About</a>
            </div>
            
            <div className={`mobile-menu-item ${activeDropdown === 0 ? "active" : ""}`}>
              <div className="mobile-menu-header">
                <a 
                  href="https://abmrc.com/products/"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSubmenu(0);
                  }}
                >
                  Products
                </a>
                <button className="dropdown-toggle" onClick={() => toggleSubmenu(0)}>
                  <i className={`fas ${activeDropdown === 0 ? "fa-minus" : "fa-plus"}`}></i>
                </button>
              </div>
              {activeDropdown === 0 && (
                <ul className="mobile-submenu">
                  <li><a href="https://abmrc.com/home-care/">Home Care Solutions</a></li>
                  <li><a href="https://abmrc.com/hospital-care/">Hospital Care Solutions</a></li>
                </ul>
              )}
            </div>
            
            <div className={`mobile-menu-item ${activeDropdown === 1 ? "active" : ""}`}>
              <div className="mobile-menu-header">
                <a 
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSubmenu(1);
                  }}
                >
                  Resources
                </a>
                <button className="dropdown-toggle" onClick={() => toggleSubmenu(1)}>
                  <i className={`fas ${activeDropdown === 1 ? "fa-minus" : "fa-plus"}`}></i>
                </button>
              </div>
              {activeDropdown === 1 && (
                <ul className="mobile-submenu">
                  <li><a href="https://abmrc.com/medical-education/">Medical Education</a></li>
                  <li><a href="https://abmrc.com/press-releases/">Press Releases</a></li>
                  <li><a href="https://abmrc.com/government-programs/">Government Programs</a></li>
                  <li><a href="https://abmrc.com/news/">News</a></li>
                  <li><a href="https://eifu.abmrc.com/">Manuals</a></li>
                </ul>
              )}
            </div>
            
            <div className="mobile-menu-item">
              <a href="https://abmrc.com/contact/">Contact us</a>
            </div>
            
            {/* Mobile Social Icons */}
            <div className="mobile-social">
              <a href="https://www.linkedin.com/company/abmrc/" target="_blank" rel="noopener noreferrer" className="linkedin">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://www.facebook.com/ABM-Respiratory-Care-102320835281322" target="_blank" rel="noopener noreferrer" className="facebook">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://twitter.com/abmcare?lang=en" target="_blank" rel="noopener noreferrer" className="twitter">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;