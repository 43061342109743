/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  logIn,
  signIn,
  confirmOTP,
  userLoginData,
  userPendingData,
  resetLoginErrorMsg,
  resend_otp_login,
  makeOtpResendLoginEmpty,
} from "../../../redux/user/user.action";

import {
  loginUser,
  updateInvitationItem,
  AlertMsg,
} from "../../../redux/invitation/components/invitation.action";
import "./styles.css";

import "../../../assets/styles/font.css";
import CustomSnackbar from "../../../components/Alertmessage/AlertMsg";
import UploadCSV from "../../../components/Report/UploadCSV";
import Footer from "./footer";
import TopBar from "./topbar";
const Login = (props) => {
  const location = useLocation();
  // const classes = useStyles();
  const [otp, setopt] = useState("");
  const [values, setValues] = React.useState({
    username_email: location?.state?.email,
  });
  const [openuploadcsv, setopenuploadcsv] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("error");
  const [snackMsg, setSnackmsg] = React.useState("");
  const [anchororigin, setanchorOrigin] = useState({
    vertical: "top",
    horizontal: "center",
  });
  var history = useHistory();
  // const location = useLocation()
  // const [emailfromregister,setemailregister]  = useState('')
  const inputRefs = React.useRef([React.createRef(), React.createRef()]);
  const [counter, setCounter] = React.useState(300);
  const otpRef = React.createRef();
  const [resendOtpConditaion, setresendotpCondition] =
    React.useState("resendotp");
  const [clickNextButton, SetClickNextButton] = React.useState(false);
  const getMaskedNumber = (phone_number) => {
    if (phone_number) {
      let digits = phone_number.slice(phone_number.length - 3);
      let phone_num = "x".repeat(phone_number.length - 3);
      phone_num = phone_num + digits;
      return phone_num;
    }
    return "";
  };
  const handleInputChange = (name, value) => {
    if (value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  function openuploadcsvpopup() {
    setopenuploadcsv(true);
  }
  function closeUploadCsvPopup() {
    setopenuploadcsv(false);
  }

  const handleSnackbar = () => {
    setOpenSnack(false);
    props.resetLoginErrorMsg();
  };

  return (
    <>
      <CustomSnackbar
        flag={openSnack}
        onClose={handleSnackbar}
        anchorOrigin={anchororigin}
        severity={snackSeverity}
        msg={snackMsg}
      />
      <UploadCSV isopen={openuploadcsv} closePopup={closeUploadCsvPopup} />
      <div>
        <TopBar />
      </div>
      <div className="h-full">
        {/* Coming Soon Section */}
        <div className="coming-soon-section">
       
          <div className="coming-text">
            <h1>Coming Soon!</h1>
            <p>
              A digital companion designed to support patients
              and caregivers in managing their respiratory health.
            </p>
          </div>
          <div className="coming-logo">
            <img src="arc.png" alt="Arc Connect For BiWaze" />
          </div>
        </div>

        {/* Green Section */}
        <div className="green-section">
          <div className="section-item">
          <button className="analysis-button" onClick={openuploadcsvpopup}>
            Decrypt and Analyze <br /> BiWaze® Cough Reports
          </button>
          <p className="analysis-text">
            Use this online tool to decrypt log files and create a customized report
            with the BiWaze® Cough therapy data.
            </p>
            </div>
        </div>

        {/* Footer */}
        <div className="footer">
          <Footer />
        </div>
      </div>

    </>
  );
};
const mapStateToProps = (store) => {
  return {
    msg: store.invitation.msg,
    otpSent: store.user.otpSent,
    otpToken: store.user.otpToken,
    user: store.user.user,
    isMatch: store.user.isMatch,
    loginOtp: store.user.loginOtp,
    loginErrorMsg: store.user.loginErrorMsg,
    otpexpireerror: store.user.otpexpireerror,
    resendotpcodelogin: store.user.resendotpcodelogin,
    phone: store.user.phone,
  };
};
export default connect(mapStateToProps, {
  logIn,
  loginUser,
  updateInvitationItem,
  AlertMsg,
  signIn,
  confirmOTP,
  userLoginData,
  userPendingData,
  resetLoginErrorMsg,
  resend_otp_login,
  makeOtpResendLoginEmpty,
})(Login);
