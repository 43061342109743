export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">

                {/* Left Section (Logo & Description) */}
                <div className="footer-left">
                    <img src="https://abmrc.com/wp-content/uploads/2021/02/ABM-Logo-08.png" alt="ABM Logo" />
                    <p>
                        ABM Respiratory Care will advance the healthcare industry by developing intelligent,
                        clinically differentiated, and innovative respiratory care solutions to help people
                        breathe better inside and outside the hospital.
                    </p>
                </div>

                {/* Center Section (Links) */}
                <div className="footer-center">
                    <div className="footer-section">
                        <h3>Information</h3>
                        <ul>
                            <li><a href="/about/">About Us</a></li>
                            <li><a href="https://abmrc.com/about/#leadership-team">Leadership Team</a></li>
                            <li><a href="https://abmrc.com/about/#locations">Location</a></li>
                            <li><a href="https://abmrc.com/home-care/">Home Care</a></li>
                            <li><a href="https://abmrc.com/hospital-care/">Hospital Care</a></li>
                        </ul>
                    </div>

                    <div className="footer-section">
                        <h3>Resources</h3>
                        <ul>
                            <li><a href="/medical-education/">Medical Education</a></li>
                            <li><a href="/press-releases/">Press Releases</a></li>
                            <li><a href="https://abmrc.com/government-programs/">Government Programs</a></li>
                            <li><a href="/news/">News</a></li>
                            <li><a href="https://eifu.abmrc.com/">Manuals</a></li>
                        </ul>
                    </div>
                    
                    <div className="footer-section mt-5">
                        <h3>Products</h3>
                        <ul>
                            <li><a href="https://abmrc.com/biwaze/">BiWaze Cough</a></li>
                            <li><a href="https://abmrc.com/biwaze-clear-home-care/">BiWaze Clear Home</a></li>
                            <li><a href="/biwaze-clear-hospital-care/">BiWaze Clear Hospital</a></li>
                        </ul>
                    </div>


                    <div className="footer-section  mt-5">
                        <h3>Contact</h3>
                        <ul>
                            <li><a href="/schedule-a-demo/">Schedule a Demo</a></li>
                            <li><a href="/contact/">Request Information</a></li>
                        </ul>
                    </div>
                </div>

                {/* Right Section (Policies & Social Links) */}
                <div className="footer-right">
                    <ul>
                        <li><a href="/purchasing-policy/">Purchasing Policy</a></li>
                        <li><a href="/privacy-policy/">Privacy Policy</a></li>
                        <li><a href="/terms-of-use/">Terms of Use</a></li>
                    </ul>

                    <div className="footer-social">
                        <a href="https://www.linkedin.com/company/abmrc/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="https://www.facebook.com/ABM-Respiratory-Care-102320835281322" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook"></i>
                        </a>
                        <a href="https://twitter.com/abmcare?lang=en" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <strong>©{new Date().getFullYear()} by ABM Respiratory Care</strong>
            </div>
        </footer>
    );
}