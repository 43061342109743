import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Store from "./store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import App from "./app";
import { TourProvider } from "../src/screens/PublicRoutes/Decrypt/TourContext";

ReactDOM.render(
  <Provider store={Store}>
    <TourProvider>
    <App />
  </TourProvider>,
  </Provider>,
  document.getElementById("root")
);
