import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions } from "@mui/material";

const AnnotationPopup = ({ isOpen, onClose, onSubmit }) => {
    const [text, setText] = useState("");

    const handleAdd = () => {
        if (text.trim()) {
            onSubmit(text);
            setText("");
            onClose();
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    background: "rgba(255, 255, 255, 0.15)", // Slightly more transparent
                    backdropFilter: "blur(12px)", // Stronger blur effect
                    borderRadius: "15px", // Smooth rounded corners
                    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.3)", // Stronger shadow
                    border: "2px solid rgba(255, 255, 255, 0.4)", // Cleaner border
                }
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#222", // Darker text for contrast
                    letterSpacing: "0.5px"
                }}
            >
                Add Annotation
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    padding: "20px",
                    color: "#333",
                }}
            >
                <TextField
                    placeholder="Enter annotation text"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "rgba(255, 255, 255, 0.6)", // Light transparent bg
                            borderRadius: "10px", // Keep rounded corners
                            border: "1px solid rgba(0, 0, 0, 0.2)", // Normal border
                            transition: "border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition
                            "&:hover": {
                                borderColor: "rgba(0, 0, 0, 0.4)", // Darker on hover
                            },
                            "&.Mui-focused": {
                                borderColor: "#007BFF", // Change active border color
                                boxShadow: "0 0 8px rgba(0, 123, 255, 0.5)", // Soft blue glow
                                borderRadius: "10px", // Ensure border-radius is maintained
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none", // Remove default outline
                            },
                        },
                    }}
                />
                <Button
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    disabled={!text.trim()}
                    sx={{
                        borderRadius: "8px",
                        fontWeight: "bold",
                        // textTransform: "none",
                        alignSelf: "flex-end" // Align button to the right (optional)
                    }}
                >
                    Add
                </Button>
            </DialogContent>


            {/* <DialogActions sx={{ padding: "15px", justifyContent: "center" }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: "8px", fontWeight: "bold", textTransform: "none" }}
                >
                    Cancel
                </Button>
            </DialogActions> */}
        </Dialog>

    );
};

export default AnnotationPopup;
