import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../screens/PublicRoutes/Signin";
import Welcome from "../screens/PublicRoutes/Welcome";
// import Login from "../views/auth/Login"
import Signup from "../views/auth/Register";
import AcceptInvitation from "../screens/Common/acceptClinicInvitation";
import Forgotpassword from "../screens/PublicRoutes/Signin/Forgotpassword";
import Restorepasswordwithphone from "../screens/PublicRoutes/Signin/RestorePassword";
import Restorepasswordwithemail from "../screens/PublicRoutes/Signin/Restorepasswordwithemail";
import DeviceStatsTable from "../screens/PublicRoutes/DeviceStats";
import ReportPage from "../screens/PublicRoutes/Decrypt";
import Dashboard from "../screens/PublicRoutes/DashBoard";
function PublicRoutes(props) {
  return (
    <Fragment>
      <Switch>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/acceptinvite">
          <AcceptInvitation />
        </Route>
        <Route path="/forgotpassword">
          <Forgotpassword />
        </Route>
        <Route path="/restorepasswordwithemail">
          <Restorepasswordwithemail />
        </Route>
        <Route path="/restorepasswordwithphone">
          <Restorepasswordwithphone />
        </Route>
        <Route path="/deviceStats">
          <DeviceStatsTable />
        </Route>
        <Route path="/decrypt">
          <ReportPage />
        </Route>
        <Route path="/dash">
          <Dashboard />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route exact path="">
          <Welcome />
        </Route>
      </Switch>
    </Fragment>
  );
}

export default PublicRoutes;
